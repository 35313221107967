import styled from "styled-components/macro";

export default styled.div`
  
  .nav-bar {
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    position: sticky;
    top: 0px;
    z-index: 999;
    -webkit-box-shadow: 0 9px 5px -7px ${({ theme }) => theme?.colors?.["1"]};
    -moz-box-shadow: 0 9px 5px -7px ${({ theme }) => theme?.colors?.["1"]};
         box-shadow: 0 9px 5px -7px ${({ theme }) => theme?.colors?.["1"]};
    
  }
  .btn-enq{
    background-color:${({ theme }) => theme?.colors?.["1"]};
    color:${({ theme }) => theme?.colors?.["0"]};
  }
  .enq-div{
    border-left:2px solid grey;
    padding-left:10px;
  }

  .menu-drawer> .logo-icon {
    padding: 10px 15px;
  }
  .logo-icon {
    font-family: ${({ theme }) => theme?.fontFamily?.["0"]};
    color: ${({ theme }) => theme?.colors?.["1"]};
    font-size: ${({ theme }) => theme?.fonts?.["4"]};
    font-weight: 500;
    text-align:left;
    justify-content: space-between;
    display:inline-flex;
    margin:auto;
    align-items:center;
  }
.MuiDrawer-paper{
  width:80%
  padding:0 10px;
}
  .small-text {
    margin-top: -5px;
    color: ${({ theme }) => theme?.colors?.["2"]};
    font-size: ${({ theme }) => theme?.fonts?.["0"]};
  }
    .navbar-height {
      min-height: 80px;
      padding: 0; !important;
    }
   
  @media (max-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
    .navbar-height {
      padding: 0 24px;
  }
   
  } 
  @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    .navbar-height {
      padding: 5px 24px;
      min-height: 50px;
      border-radius:25px;
    }
   
  } 
  
  .nav-link-container {
    padding-left: 30px;
  }

  .nav-links {
    color: ${({ theme }) => theme?.colors?.["1"]};
    font-weight: bold;
    background-color: ${({ theme }) => theme?.colors?.["0"]};
    font-size: ${({ theme }) => theme?.fonts?.["1"]};
    text-decoration: auto;
  }
  .highlight-nav {
    width: 0%;
    height: 2px;
    background-color: ${({ theme }) => theme?.colors?.["2"]};
  }
  .nav-links:hover ~ .highlight-nav {
    transition: 0.4s;
    width: 100%;
  }
  .nav-links ~ .highlight-nav {
    transition: 0.4s;
    width: 0%;
  }
  .menu-btn{
    margin: auto;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color:${({ theme }) => theme?.colors?.["2"]};
    border-radius:10px;
    margin-right:0;
    @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
      height: 85%
    }
    @media (min-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
      height: 50%;
    }
  }
  @media (min-width: ${({ theme }) => theme?.deviceSize?.tablet}) {
    .desk-nav-links{
      display:flex;
    }
  }
   @media (max-width: ${({ theme }) => theme?.deviceSize?.mobile}) {
    img.logo{
      width:50px !important;
    }
  }
  img.logo{
    width: 90px;
    height: auto;
    display: block;
    margin: auto 0;
  }
 

`;
